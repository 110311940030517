




























































































































































// import { Vue, Component, Emit } from "vue-property-decorator";
import Vue from "vue";
import { Component, Prop,Emit } from "vue-property-decorator";
// import DoctorDetailService from "../../../doct/service/DoctorDetailService";
 import DoctorDetailService from "../../../doctor-app/service/DoctorDetailService";
import { ISpecialization } from "../../../common-app/models/Specialization";
import { IDoctorSpecialization } from "../../../doctor-app/models/DoctorSpecialization";
import { IDoctorExprience } from "../../..//doctor-app/models/DoctorExprience";
import { IDoctorEducation } from "../../../doctor-app/models/DoctorEducation";
import { IDoctorAward } from "../../../doctor-app/models/DoctorAward";
import { IDoctorDetail } from "../../../doctor-app/models/DoctorDetail";

@Component
export default class ProfileDetails extends Vue {
  @Prop({ default: 0 }) doctorId: number;
  public isShow: boolean = true;

  public specializationList: ISpecialization[] = [];
  public doctorSpecializationList: IDoctorSpecialization[] = [];
  public doctorExperienceList: IDoctorExprience[] = [];
  public doctorEducationList: IDoctorEducation[] = [];
  public doctorAwardList: IDoctorAward[] = [];
  public detail: IDoctorDetail = {} as IDoctorDetail;
  public profile: any = {};

  @Emit("close")
  close() {
    this.isShow = false;
    return this.isShow;
  }
  created(){
      DoctorDetailService.getProfileListForAdmin(this.doctorId).then((s) => {
      this.specializationList = s.data.specializations || [];
      this.doctorSpecializationList = s.data.doctorSpecializations || [];
      this.doctorExperienceList = s.data.doctorExperiences || [];
      this.doctorEducationList = s.data.doctorEducations || [];
      this.doctorAwardList = s.data.doctorAwards || [];
      this.detail = s.data.doctorDetails || ({} as IDoctorDetail);
      this.profile = s.data.profile || {};
    //alert(this.detail.)
    });
  }
}
