import { AuthAxios } from "../../global-config/HttpConfig";
import { ApiURIs } from "../../global-config/ApiList";
import { IHospitalDetail } from "../models/HospitalDetail";

export default {
  GetInsert(hospitalDetail: IHospitalDetail) {
        return AuthAxios.post(ApiURIs.HospitalInsert, JSON.stringify(hospitalDetail));
  },
  // GetApproved(id:number) {
  //  return AuthAxios.put(ApiURIs.HospitalApproved, JSON.stringify(id));
  // },
   GetApproved(id: number) {
    return AuthAxios.get(
      `${
        ApiURIs.HospitalApproved
      }?id=${id}`
    );
  },
  GetDeactivate(id: number) {
    return AuthAxios.get(
      `${
        ApiURIs.HospitalDeactivate
      }?id=${id}`
    );
  }
//   getDelete(id: number) {
//     return AuthAxios.delete(`${ApiURIs.DrEduDelete}/${id}`);
//   }
};




