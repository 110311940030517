












































































































































































import { Vue, Component, Emit } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Header extends Vue {
  public sidebarCollapse: boolean = true;

  @Emit("emitSidebarToggle")
  sidebarToggle() {
    this.sidebarCollapse = !this.sidebarCollapse;
    return this.sidebarCollapse;
  }
}
