

















































































































































































































































import { Vue, Component } from "vue-property-decorator";
import DoctorScheduleService from "../../../doctor-app/service/DoctorService";
import { IDoctorDetailsViewModel } from "../../../doctor-app/models/DoctorDetailsViewModel";
import AlertService from "../../../common-app/service/AlertService";
import ProfileDetails from "./ProfileDetails.vue";
import RejectReasonModal from "./RejectReasonModal.vue";
import { format } from "crypto-js";

@Component({
  components: {
    ProfileDetails,
    RejectReasonModal,
  },
})
export default class DoctorsActivity extends Vue {
  public doctors: IDoctorDetailsViewModel[] = [];
  public allDoctor: IDoctorDetailsViewModel[] = [];
  public searchText: string = "";
  public doctorStatus: number = 1;
  public docId: number = 0;
  public showProfDetails: boolean = false;
  public rejectDoctor: { doctorId: any; index: number; action: string };
  isShowDenyReason: boolean = false;
  rows = 10;
  currentPage = 1;
  perPage = 3;
  isAscending: boolean = true;

  created() {
    this.rows = 10;
    this.currentPage = 1;
    this.perPage = 10;

    DoctorScheduleService.GetListForAdmin().then((s) => {
      this.allDoctor = s.data;
      this.allDoctor = this.allDoctor.filter((s) => s.isDoctoreActive == 1);
      //console.log(this.allDoctor);
      this.rows = this.allDoctor.length;
      this.paginate(this.perPage, 0);
    });
  }

  paginate(page_size: number, page_number: number) {
    this.doctors = [];
    this.doctors = this.allDoctor.slice(page_number * page_size, (page_number + 1) * page_size);
    // console.log(this.doctors);
  }
  onPageChanged(page: number) {
    this.paginate(this.perPage, page - 1);
  }

  approve(id: number, index: number) {
    if (confirm("Do you really want to approve?")) {
      DoctorScheduleService.GetApproved(id).then((res) => {
        AlertService.sendMessage({ status: true, message: "Approved" });
        this.pendingRequest();
      });
    }
  }
  deactivate(id: number, index: number, reason: string) {
    this.isShowDenyReason = false;
    this.$bvModal
      .msgBoxConfirm("Do you really want to Deactivate?", {
        title: "Please Confirm",
        okVariant: "danger",
        okTitle: "Yes",
        cancelVariant: "success",
        cancelTitle: "No",
        hideHeaderClose: true,
        headerClass: "justify-content-center",
        bodyClass: "text-center",
        centered: true,
        footerClass: "justify-content-center border-top-0 flex-wrap-reverse",
      })
      .then((value) => {
        if (value == true) {
          DoctorScheduleService.GetDeactivate(id, reason).then((res) => {
            this.activeDoctor();
            AlertService.sendMessage({ status: false, message: "Deactivate" });
          });
        }
      });
  }
  searchdoctor() {
    this.allDoctor = [];

    DoctorScheduleService.GetListForAdmin().then((s) => {
      this.allDoctor = s.data;
      this.allDoctor = this.allDoctor.filter(
        (s) => s.phone.toLowerCase().includes(this.searchText.toLowerCase()) && s.isDoctoreActive == this.doctorStatus
      );
      // console.log(this.doctors.length);
      this.rows = this.allDoctor.length;
      this.paginate(this.perPage, 0);
    });
  }

  revoked(id: number, index: number, reason: string) {
    // if (confirm("Do you really want to revoked?")) {
    //   DoctorScheduleService.GetRevoked(id).then((res) => {
    //     this.activeDoctor();
    //     AlertService.sendMessage({ status: false, message: "revoked" });
    //   });
    // }

    this.isShowDenyReason = false;
    this.$bvModal
      .msgBoxConfirm("Do you really want to Revoked?", {
        title: "Please Confirm",
        okVariant: "danger",
        okTitle: "Yes",
        cancelVariant: "success",
        cancelTitle: "No",
        hideHeaderClose: true,
        headerClass: "justify-content-center",
        bodyClass: "text-center",
        centered: true,
        footerClass: "justify-content-center border-top-0 flex-wrap-reverse",
      })
      .then((value) => {
        if (value == true) {
          DoctorScheduleService.GetRevoked(id, reason).then((res) => {
            this.activeDoctor();
            AlertService.sendMessage({ status: false, message: "Deactivate" });
          });
        }
      });
  }
  activeDoctor() {
    this.doctorStatus = 1;
    this.allDoctor = [];
    DoctorScheduleService.GetListForAdmin().then((s) => {
      this.allDoctor = s.data;
      this.allDoctor = this.allDoctor.filter((s) => s.isDoctoreActive == 1);
      this.rows = this.allDoctor.length;
      this.paginate(this.perPage, 0);
    });
  }

  pendingRequest() {
    this.doctorStatus = 0;
    this.allDoctor = [];
    this.doctors = [];
    DoctorScheduleService.GetListForAdmin().then((s) => {
      this.allDoctor = s.data;
      //  console.log(this.allDoctor);
      this.allDoctor = this.allDoctor.filter((s) => s.isDoctoreActive == 0);
      this.rows = this.allDoctor.length;
      this.paginate(this.perPage, 0);
    });
  }
  openProfileDetails(docid: number) {
    this.docId = docid;
    this.showProfDetails = true;
  }
  closeProfDetails(show: any) {
    this.showProfDetails = show;
  }
  openRejectReason(doctorId: number, index: number, action: string) {
    this.rejectDoctor = { doctorId: doctorId, index: index, action: action };
    this.isShowDenyReason = true;
    // this.rejectDoctor = doctor;
    this.docId = doctorId;
  }
  closeRejectReason(hide: any) {
    this.isShowDenyReason = hide;
  }
  // savedRejectReason(hide: boolean) {
  savedRejectReason(event: string) {
    this.isShowDenyReason = true;
    if (this.rejectDoctor.action == "Deny") {
      this.deactivate(this.rejectDoctor.doctorId, this.rejectDoctor.index, event);
    } else if (this.rejectDoctor.action == "Revoked") {
      this.revoked(this.rejectDoctor.doctorId, this.rejectDoctor.index, event);
    }
  }
  sortBy(name: string) {
    this.isAscending = !this.isAscending;
  }
}
