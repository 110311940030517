











































import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class SideNav extends Vue {
  @Prop(Boolean) private sidebarCollapseState!: boolean;
}
