


































import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component({
  components: {},
})
export default class RejectReasonModal extends Vue {
  isShow: boolean = true;
  reasonDetails: string = "";
  reason: string = "";
  rejectReason: string = "";
  rejectReasonData: string[] = ["BMDC Reg No is not correct", "Your name doesn’t match NID","Does not comply with BDDOT policy(Revoked)", "others"];
  @Emit("close")
  close() {
    this.isShow = false;
    return this.isShow;
  }
  @Emit("save")
  save() {
    //console.log(this.rejectReason);

    this.isShow = false;
     this.reasonDetails;
    return this.reason=this.rejectReason=="others"?this.reasonDetails:this.rejectReason;
  }
}
