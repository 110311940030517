import { AuthAxios } from "../../global-config/HttpConfig";
import { ApiURIs } from "../../global-config/ApiList";
import { IDoctorDetail } from "../models/DoctorDetail";

export default {
  getById() { },
  async getProfileList() {
    return await AuthAxios.get(ApiURIs.detailGetProfileList);
  },
  async getProfileListForAdmin(id: number) {
    return await AuthAxios.get(`${
      ApiURIs.detailGetProfileListForAdmin
    }?id=${id}`);
  },
  getCurrentDoctorDetail() {
    return AuthAxios.get(ApiURIs.detailGetByCurrentUser);
  },
  Insert(detail: IDoctorDetail) {
    return AuthAxios.post(ApiURIs.detailInsert, JSON.stringify(detail));
  },
  Update(detail: IDoctorDetail) {
    return AuthAxios.put(ApiURIs.detailUpdate, JSON.stringify(detail));
  },
  submitProfile() {
    return AuthAxios.get(ApiURIs.detailProfileComplete);
  }
};
