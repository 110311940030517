













































































































































































































































import { Vue, Component } from "vue-property-decorator";
import HospitalService from "../../../diagnostic-app/service/HospitalService";
import HospitalDetailService from "../../../diagnostic-app/service/HospitalDetailService";
import { IHospitalDetail } from "../../../diagnostic-app/models/HospitalDetail";

@Component({
  components: {},
})
export default class HospitalList extends Vue {
  public allHospital: IHospitalDetail[] = [];

  created() {
    HospitalService.GetList().then((s) => {
      this.allHospital = s.data;
      this.allHospital = this.allHospital.filter((s) => s.isActive == 1);
    });
  }

  approve(id: number, index: number) {
    if (confirm("Do you really want to approve?")) {
      HospitalDetailService.GetApproved(id).then((res) => {
        alert("Approved");
        this.pendingRequest();
      });
    }
  }
  dactivate(id: number, index: number) {
    if (confirm("Do you really want to Deactivate?")) {
      HospitalDetailService.GetDeactivate(id).then((res) => {
        this.activeHospital();
        alert("Deactivate");
      });
    }
  }

  pendingRequest() {
    this.allHospital = [];
    HospitalService.GetList().then((s) => {
      this.allHospital = s.data;
      this.allHospital = this.allHospital.filter((s) => s.isActive == 0);
    });
  }

  activeHospital() {
    this.allHospital = [];
    HospitalService.GetList().then((s) => {
      this.allHospital = s.data;
      this.allHospital = this.allHospital.filter((s) => s.isActive == 1);
    });
  }
}
