












































































































































import { Vue, Component } from "vue-property-decorator";
import UserService from "../../service/UserService";

import { IUser } from "../../../common-app/models/User";

@Component({
  components: {},
})
export default class UserList extends Vue {
public userDetail: IUser[] = [];
public userDetailList: IUser[] = [];
public users: IUser[] = [];
public userDetailActive: IUser[] = [];
public searchText: string = "";

rows = 10;
currentPage = 1;
perPage = 10;


  created(){
    this.rows = 10;
    this.currentPage = 1;
    this.perPage = 10;

    UserService.GetList().then((s)=>{
      //this.userDetail=s.data.result.filter((s: { isActive: number; }) => s.isActive == 1 /*&& s.role == 2*/ );
      this.userDetail=s.data.result.filter((s: { isActive: number,role:number }) => /*s.isActive == 1 &&*/ s.role == 2  );

      this.rows = this.userDetail.length;
      this.paginate(this.perPage, 0);
       // console.log(this.userDetail);
    });
  }

  searchUser() {  
      this.userDetailList = this.userDetail.filter(
        (s) => s.phone.toLowerCase().includes(this.searchText.toLowerCase())
      );   
      this.rows = this.userDetailList.length;
      this.paginate(this.perPage, 0); 
  }

  updateState(id:number,status:number){
    if (confirm("Are you sure?")) {
    UserService.UpdateUserStatus(id,status).then((s)=>{
      //console.log(s.data);
    });
  }
  }

  paginate(page_size: number, page_number: number) {
    this.users = [];
    if(this.userDetailList.length<1)
    {
      this.userDetailList=this.userDetail;
    }
    this.users = this.userDetailList.slice(page_number * page_size, (page_number + 1) * page_size);
    this.userDetailList=[];
  }
  onPageChanged(page: number) {
    this.paginate(this.perPage, page - 1);
  }

}
