import { AuthAxios } from "../../global-config/HttpConfig";
import { ApiURIs } from "../../global-config/ApiList";

export default {
  GetAgentList() {
    return AuthAxios.get(ApiURIs.AgentList);
  },
  AssignAsAgent(Id: number) {
    return AuthAxios.post(ApiURIs.AssignAsAgent + "?Id=" + Id);
  },
  RemoveAsAgent(Id: number) {
    //console.log(Id);
    return AuthAxios.post(ApiURIs.removeAsAgent + "?Id=" + Id);
  }, 
  UpdateUserStatus(userId: number,status:number) {
    return AuthAxios.get(
      `${
        ApiURIs.UserUpdateStatus
      }?userId=${userId}&status=${status}`
    );
  }
  ,
  GetList() {
    return AuthAxios.get(ApiURIs.GetUserList);
  }
};
