import { AuthAxios } from "../../global-config/HttpConfig";
import { ApiURIs } from "../../global-config/ApiList";
import { Subject } from "rxjs";

const refreshProfileCompleteSubject = new Subject<boolean>();
export default {
  refreshProfileCompleteStstus: (refresh: boolean) => {
    //console.log("refreshAptSchedule", refresh);

    refreshProfileCompleteSubject.next(refresh);
  },
  refreshProfileComplete: () => {
    return refreshProfileCompleteSubject.asObservable();
  },
  GetList() {
    return AuthAxios.get(ApiURIs.DrList);
  },

  GetListForAdmin() {
    return AuthAxios.get(ApiURIs.DrListForAdmin);
  },
  FeatureDrList() {
    return AuthAxios.get(ApiURIs.FeatureDrList);
  },
  GetSearch(distId: number, specializationId: number) {
    return AuthAxios.get(
      `${
        ApiURIs.DrGetSearch
      }?distId=${distId}&specializationId=${specializationId}`
    );
  },
  GetSearchForThana(distId: number, specializationId: number) {
    return AuthAxios.get(
      `${
       ApiURIs.DrGetSearchForThana
      }?distId=${distId}&specializationId=${specializationId}`
    );
  },
  GetApproved(id: number) {
    return AuthAxios.get(
      `${
        ApiURIs.DoctorApproved
      }?id=${id}`
    );
  },
  GetDeactivate(id: number,rejectreason:string) {
    return AuthAxios.get(
      `${
        ApiURIs.DoctorDeactivate
      }?id=${id}&reason=${rejectreason}`
    );
  },

  GetRevoked(id: number,rejectreason:string) {
    return AuthAxios.get(
      `${
        ApiURIs.DoctorRevoke
      }?id=${id}&reason=${rejectreason}`
    );
  },
  getDoctorSearch(
    type: number,
    searchKey: string,
    districtId: number = 0,
    specializationId: number = 0
  ) {
    return AuthAxios.get(
      `${
        ApiURIs.DrSearch
      }?type=${type}&searchKey=${searchKey}&districtId=${districtId}&specializationId=${specializationId}`
    );
  },

  getAdvanceSearch(
    districtId: number ,
    specializationId: number ,
    gender:number,
    thanaId:string,
    fees:number,
    orderBy:number
  ) {
    return AuthAxios.get(
      `${
        ApiURIs.DrAdvanceSearch
      }?districtId=${districtId}&specializationId=${specializationId}&gender=${gender}&thanaId=${thanaId}&fees=${fees}&orderBy=${orderBy}`
    );
  },

  GetChambers(id: number) {
    return AuthAxios.get(`${ApiURIs.DrGetChambersById}/${id}`);
  },
  GetDetails(id: number) {
    return AuthAxios.get(`${ApiURIs.DrDetails}/${id}`);
  },
  GetChamberSchedule(chamberId: number) {
    return AuthAxios.get(`${ApiURIs.DrGetScheduleChamberId}/${chamberId}`);
  }
};
